// i18next-extract-mark-ns-start installment-payments-for-shopify

//Landing redirected to /payment-methods to hide from BdE license - Discussed with David and Alex on 2024-08-08

//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import shopify_cofidis from 'images/shopify_cofidis.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="installment-payments-for-shopify"
        title={t('Shopify Installment Payments')}
        description={t(
          'Add Shopify installments payments to your store to let customers pay in 4 while you get paid in full. You’ll boost customer satisfaction and sales. Start now ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Quickly and easily accept installment payments in your Shopify store</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Accepting installment payments in your Shopify store leads to more sales, improved
                customer satisfaction, and boosts brand loyalty. If customers can{' '}
                <BlogLink slug="buy-now-pay-later">buy now and pay later</BlogLink>, they’ll visit
                your store more often and you’ll enjoy increased customer lifetime value. But you’ll
                need a payment service provider that supports installment payments and integrates
                with Shopify.
              </Trans>
              <Trans parent="p">
                Use our advanced e-commerce payment gateway to accept the widest range of payment
                methods in{' '}
                <InternalPageLink slug="shopify-payment-gateway">
                  your Shopify store
                </InternalPageLink>
                .
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={shopify_cofidis} alt="MONEI cofidis" title="MONEI cofidis" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Build trust and sell more by adding Cofidis installment payments to your Shopify
                  store
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                As Spain’s leader in consumer credit,{' '}
                <InternalPageLink slug="payment-methods/cofidis">Cofidis</InternalPageLink> is
                widely used and trusted. Add installment payments to your Shopify store to get paid
                in full when the order is placed, while your customers enjoy shopping now and paying
                later. You’ll never have to worry about chasing customers for money and letting them
                pay later builds trust in your brand.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>How Installment Payments by Cofidis work</Trans>
              </SectionHeader>
              <Trans parent="p">
                After you create your MONEI account and{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/articles/4402319128465-How-do-I-active-Cofidis-installment-payments-">
                  configure the Cofidis payment method
                </AnchorLink>
                , your customers can select installment payments at checkout (on purchases from €75
                to €1,000).
              </Trans>
              <Trans parent="p">
                The customer <Link to="/">checkout process</Link> is simple. All they have to do is
                provide their credit card details and personal ID, and with a few clicks, the order
                will be finished.
              </Trans>
              <Trans parent="p">
                The first payment happens at the time of purchase and the balance customer
                transactions occur automatically 30, 60, and 90 days after the initial payment.
                You’ll get paid in full one day after the order is placed.
              </Trans>
              <Trans parent="p">
                For more information about enabling Cofidis and payouts,{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/sections/360006008278-Installment-payments-by-Cofidis-">
                  go here
                </AnchorLink>
                .
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Why use MONEI’s e-commerce payment gateway?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting as many payment methods as possible is critical in e-commerce. In addition
                to pay later options like Cofidis, consumers today are looking for more ways to pay
                online. This includes{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/paypal">Paypal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>{' '}
                as well as <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                such as <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in
                Spain.
              </Trans>
              <Trans parent="p">
                We’re here to help you accept the widest range of{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                so you can reach more people, improve customer satisfaction, and increase sales.
                That’s why we aggregate all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into one easy-to-use platform.
              </Trans>
              <Trans parent="p">
                And we also don’t want to kill your profit margins with high transaction fees. As
                you sell more, your transaction fees will decrease in real-time. You’ll make more
                money per sale that you can reinvest to grow your business.
              </Trans>
              <Trans parent="p">
                Use a flexible payment gateway that scales with your Shopify store —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Integrate installment payments (and more) with your Shopify store today</Trans>
            </SectionHeader>
            <Trans parent="p">
              Quickly and easily connect your Shopify store with MONEI in a few short steps to start
              accepting more payment methods today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/">
                  Connect MONEI
                </AnchorLink>{' '}
                to your Shopify store
              </Trans>
              <Trans parent="li">
                Follow the instructions{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/sections/360006008278-Installment-payments-by-Cofidis-">
                  here
                </AnchorLink>{' '}
                to enable Installment Payments by Cofidis
              </Trans>
              <Trans parent="li">
                Learn how to configure all payment methods{' '}
                <AnchorLink href="https://support.monei.com/hc/es-es/categories/360003378957-Payment-methods">
                  here
                </AnchorLink>
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "installment-payments-for-shopify"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
